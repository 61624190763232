/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ListBrokersResponseBrokersInner,
    ListBrokersResponseBrokersInnerFromJSON,
    ListBrokersResponseBrokersInnerFromJSONTyped,
    ListBrokersResponseBrokersInnerToJSON,
} from './ListBrokersResponseBrokersInner';

/**
 * 
 * @export
 * @interface ListBrokersResponse
 */
export interface ListBrokersResponse {
    /**
     * 
     * @type {Array<ListBrokersResponseBrokersInner>}
     * @memberof ListBrokersResponse
     */
    brokers: Array<ListBrokersResponseBrokersInner>;
}

export function ListBrokersResponseFromJSON(json: any): ListBrokersResponse {
    return ListBrokersResponseFromJSONTyped(json, false);
}

export function ListBrokersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListBrokersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'brokers': ((json['brokers'] as Array<any>).map(ListBrokersResponseBrokersInnerFromJSON)),
    };
}

export function ListBrokersResponseToJSON(value?: ListBrokersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'brokers': ((value.brokers as Array<any>).map(ListBrokersResponseBrokersInnerToJSON)),
    };
}

