/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateLessorUserRequestUpdateLessorUser
 */
export interface UpdateLessorUserRequestUpdateLessorUser {
    /**
     * 
     * @type {string}
     * @memberof UpdateLessorUserRequestUpdateLessorUser
     */
    imageUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateLessorUserRequestUpdateLessorUser
     */
    lessorBranchId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateLessorUserRequestUpdateLessorUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLessorUserRequestUpdateLessorUser
     */
    phoneNumber: string;
}

export function UpdateLessorUserRequestUpdateLessorUserFromJSON(json: any): UpdateLessorUserRequestUpdateLessorUser {
    return UpdateLessorUserRequestUpdateLessorUserFromJSONTyped(json, false);
}

export function UpdateLessorUserRequestUpdateLessorUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLessorUserRequestUpdateLessorUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'lessorBranchId': !exists(json, 'lessorBranchId') ? undefined : json['lessorBranchId'],
        'name': json['name'],
        'phoneNumber': json['phoneNumber'],
    };
}

export function UpdateLessorUserRequestUpdateLessorUserToJSON(value?: UpdateLessorUserRequestUpdateLessorUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imageUrl': value.imageUrl,
        'lessorBranchId': value.lessorBranchId,
        'name': value.name,
        'phoneNumber': value.phoneNumber,
    };
}

