import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import React from "react";
import {
  Color,
  Spacing,
  Shadow,
  RadiusSize,
  ContainerSize,
} from "../../../tokens";
import Container from "../../uiParts/layout/Container";

type Props = {
  heading?: string;
  supplement?: string;
  children: React.ReactNode;
  message?: React.ReactNode;
};

const AuthContainer: React.FC<Props> = ({
  heading,
  supplement,
  children,
  message,
}: Props) => {
  return (
    <div css={signupForm}>
      <Container width={ContainerSize[480]}>
        <div css={inner}>
          <div css={box}>
            {heading && (
              <div css={boxHeadingWrapper}>
                <h1 css={boxHeading}>{heading}</h1>
                {supplement && <p css={boxSupplement}>{supplement}</p>}
              </div>
            )}
            <div css={boxChildren}>{children}</div>
          </div>
          {message && <div css={messageStyle}>{message}</div>}
        </div>
      </Container>
    </div>
  );
};

const signupForm = css`
  background: ${Color.Neutral.Pale.Primary};
  text-align: center;
  min-height: 100vh;
`;

const inner = css`
  padding: ${Spacing[80]} ${Spacing[16]};
`;

const box = css`
  font-size: ${text.size[14]};
  background: ${Color.White};
  padding: ${Spacing[32]} ${Spacing[40]};
  box-shadow: ${Shadow.Box[1]};
  border-radius: ${RadiusSize[2]};
`;

const boxHeadingWrapper = css`
  margin-bottom: ${Spacing[24]};
`;

const boxHeading = css`
  font-size: ${text.size[20]};
`;

const boxSupplement = css`
  font-size: ${text.size[12]};
  color: ${Color.Neutral.Base.Secondary};
  margin-top: ${Spacing[2]};
`;

const boxChildren = css`
  text-align: left;
  word-break: break-all;
`;

const messageStyle = css`
  margin-top: ${Spacing[24]};
`;

export default AuthContainer;
