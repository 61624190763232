/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeleteDeniedBrokerRequest,
    DeleteDeniedBrokerRequestFromJSON,
    DeleteDeniedBrokerRequestToJSON,
    DenyBrokerRequest,
    DenyBrokerRequestFromJSON,
    DenyBrokerRequestToJSON,
    GetLessorUserMeResponse,
    GetLessorUserMeResponseFromJSON,
    GetLessorUserMeResponseToJSON,
    ListBrokersResponse,
    ListBrokersResponseFromJSON,
    ListBrokersResponseToJSON,
    ListDeniedBrokerUsersResponse,
    ListDeniedBrokerUsersResponseFromJSON,
    ListDeniedBrokerUsersResponseToJSON,
    UpdateLessorUserRequest,
    UpdateLessorUserRequestFromJSON,
    UpdateLessorUserRequestToJSON,
} from '../models';

export interface ApiDeniedBrokersDeleteRequest {
    body: DeleteDeniedBrokerRequest;
}

export interface ApiDeniedBrokersPostRequest {
    body: DenyBrokerRequest;
}

export interface ApiLessorUsersMePostRequest {
    body: UpdateLessorUserRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async apiBrokersGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ListBrokersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/brokers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListBrokersResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiBrokersGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ListBrokersResponse> {
        const response = await this.apiBrokersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDeniedBrokersDeleteRaw(requestParameters: ApiDeniedBrokersDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling apiDeniedBrokersDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/denied_brokers`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteDeniedBrokerRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDeniedBrokersDelete(requestParameters: ApiDeniedBrokersDeleteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiDeniedBrokersDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiDeniedBrokersGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ListDeniedBrokerUsersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/denied_brokers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDeniedBrokerUsersResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDeniedBrokersGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ListDeniedBrokerUsersResponse> {
        const response = await this.apiDeniedBrokersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDeniedBrokersPostRaw(requestParameters: ApiDeniedBrokersPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling apiDeniedBrokersPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/denied_brokers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DenyBrokerRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDeniedBrokersPost(requestParameters: ApiDeniedBrokersPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiDeniedBrokersPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiLessorUsersMeGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GetLessorUserMeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lessor_users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLessorUserMeResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiLessorUsersMeGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GetLessorUserMeResponse> {
        const response = await this.apiLessorUsersMeGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiLessorUsersMePostRaw(requestParameters: ApiLessorUsersMePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling apiLessorUsersMePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/lessor_users/me`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLessorUserRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiLessorUsersMePost(requestParameters: ApiLessorUsersMePostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiLessorUsersMePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async clientApiHealthGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/client-api/health`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async clientApiHealthGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.clientApiHealthGetRaw(initOverrides);
        return await response.value();
    }

}
