/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UpdateLessorUserRequestUpdateLessorUser,
    UpdateLessorUserRequestUpdateLessorUserFromJSON,
    UpdateLessorUserRequestUpdateLessorUserFromJSONTyped,
    UpdateLessorUserRequestUpdateLessorUserToJSON,
} from './UpdateLessorUserRequestUpdateLessorUser';

/**
 * 
 * @export
 * @interface UpdateLessorUserRequest
 */
export interface UpdateLessorUserRequest {
    /**
     * 
     * @type {UpdateLessorUserRequestUpdateLessorUser}
     * @memberof UpdateLessorUserRequest
     */
    updateLessorUser: UpdateLessorUserRequestUpdateLessorUser;
}

export function UpdateLessorUserRequestFromJSON(json: any): UpdateLessorUserRequest {
    return UpdateLessorUserRequestFromJSONTyped(json, false);
}

export function UpdateLessorUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLessorUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updateLessorUser': UpdateLessorUserRequestUpdateLessorUserFromJSON(json['updateLessorUser']),
    };
}

export function UpdateLessorUserRequestToJSON(value?: UpdateLessorUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updateLessorUser': UpdateLessorUserRequestUpdateLessorUserToJSON(value.updateLessorUser),
    };
}

