import { css } from "@emotion/react";
import { forwardRef } from "react";
import { Color } from "../../../tokens";
import LoadingHandler from "../../uiParts/feedback/LoadingHandler";

type Props = {
  isGray?: boolean;
  isLoading?: boolean;
  children: React.ReactNode;
};

const Main = forwardRef<HTMLDivElement, Props>(function Main(
  { children, isGray, isLoading = false },
  ref,
) {
  return (
    <div css={[main(isGray)]}>
      <div
        css={[mainInnerStyle, isLoading && mainInnerWithLoadingStyle]}
        ref={ref}
      >
        {children}
      </div>
      {isLoading && <LoadingHandler isLoading />}
    </div>
  );
});

const main = (isGray?: boolean) => css`
  position: relative;
  height: calc(100vh - 50px);
  margin-top: 50px;
  background: ${isGray ? Color.Neutral.Pale.Primary : Color.White};
  @media print {
    height: auto;
    margin-top: 0;
    margin-left: 0;
  }
`;

const mainInnerStyle = css`
  height: inherit;
  overflow: auto;
`;

const mainInnerWithLoadingStyle = css`
  display: none;
`;

export default Main;
