import { useUser } from "@auth0/nextjs-auth0/client";
import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { SWRConfiguration } from "swr";
import AuthContainer from "../components/organisms/common/AuthContainer";
import LoadingHandler from "../components/uiParts/feedback/LoadingHandler";
import { Color, Spacing } from "../tokens";
import { useLessorUserMe } from "./useLessorUserMe";

// Note: hooksはトップレベルからしか呼べないのでlessorUserの情報もここからとる
const useAuthLessorUser = (swrConfig?: SWRConfiguration) => {
  const { user, error, isLoading } = useUser();
  const { data: lessorUserData, error: lessorUserError } =
    useLessorUserMe(swrConfig);
  const isLoadingLessorUser = user && !lessorUserData && !lessorUserError;
  const router = useRouter();
  // アカウント情報が取得できていない場合にユーザーに返すメッセージを決定する
  const getReturnMessage = () => {
    if (error) return error.message;
    if (!user) return <AuthContainer>ログインしてください</AuthContainer>;
    // メールアドレス未確認
    if (!user.email_verified) {
      return (
        <AuthContainer
          heading="メールアドレスの確認"
          message={
            <>
              <p css={heading}>メールアドレスの確認がお済みの方</p>
              <p css={body}>
                お手数ですが一度
                <NextLink href="/api/auth/logout" passHref>
                  <a css={link}>ログアウト</a>
                </NextLink>
                して、再度ログインをお試しください
              </p>
            </>
          }
        >
          ご登録いただいたメールアドレスに確認メールを送信しました。1週間以内にメールに記載されたリンクをクリックしてください。
          <br />
          <br />
          メールを確認できない場合は、forlease_support@estie.co.jpまでお問い合わせください。
        </AuthContainer>
      );
    }
    // TODO 仲介がこのページに来てしまった時の処理を書く
    // 貸主の初回登録の場合もここに来るが、初期段階では貸主が自分で初回登録をすることはない。
    return (
      <AuthContainer heading="再ログインが必要です">
        前回のログインより時間が経過したため、一度
        <NextLink href="/api/auth/logout">
          <a css={link}>ログアウト</a>
        </NextLink>
        し再度ログインし直してください。
      </AuthContainer>
    );
  };

  // auth0のログインをしていない時はリダイレクトする
  useEffect(() => {
    if (!isLoading && !user && process.browser) {
      const returnTo = encodeURIComponent(window.location.href);
      // hook の中で redirect をするのがデザイン的に良いかどうか後で考える
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.replace(`/api/auth/login?returnTo=${returnTo}`);
    }
  }, [router, isLoading, user]);

  return {
    lessorUser: lessorUserData?.lessorUser,
    component: lessorUserData ? null : (
      <LoadingHandler
        isLoading={isLoading || isLoadingLessorUser}
        css={loadingStyle}
      >
        <div>{getReturnMessage()}</div>
      </LoadingHandler>
    ),
  };
};

const heading = css`
  font-size: ${text.size[14]};
  font-weight: bold;
  text-align: center;
  margin-bottom: ${Spacing[4]};
`;

const body = css`
  font-size: ${text.size[12]};
`;

const link = css`
  color: ${Color.Primary.Base};
  &:hover {
    text-decoration: underline;
  }
`;

const loadingStyle = css`
  height: 100vh;
`;

export default useAuthLessorUser;
