import { css } from "@emotion/react";
import React from "react";

type Props = {
  children: React.ReactNode;
  width?: React.CSSProperties["maxWidth"];
  className?: string;
};

const Container: React.FC<Props> = ({ children, width, className }) => {
  return (
    <ul css={wrapperStyle(width)} className={className}>
      {children}
    </ul>
  );
};

const wrapperStyle = (maxWidth?: React.CSSProperties["maxWidth"]) => css`
  width: 100%;
  max-width: ${maxWidth ? maxWidth : "none"};
  margin: auto;
`;

export default Container;
