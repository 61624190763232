import { Configuration, DefaultApi } from "./generated";

export type { UpdateLessorUserRequestUpdateLessorUser } from "./generated";

export const API = new DefaultApi(
  new Configuration({
    basePath: "",
    middleware: [
      {
        pre: async ({ url, init }) => {
          /**
           * 自動生成した API 定義は /api/* を叩くが、
           * Next.js のを叩く必要があるので /api/proxy/* に置き換えて叩く
           */
          return {
            url: url.replace(/^\/api/, "/api/proxy"),
            init,
          };
        },
      },
    ],
  }),
);

// OpenAPI generator で生成された型は全く同じ interface でも複数の名前がつけられることがあり、
// かつ読みにくい名前なので、よく使うものはここで re-export しておく
import { GetLessorUserMeResponseLessorUser } from "./generated";
export type ApiLessorUser = GetLessorUserMeResponseLessorUser;
