/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetLessorUserMeResponseLessorUser
 */
export interface GetLessorUserMeResponseLessorUser {
    /**
     * 
     * @type {string}
     * @memberof GetLessorUserMeResponseLessorUser
     */
    auth0Id: string;
    /**
     * 
     * @type {string}
     * @memberof GetLessorUserMeResponseLessorUser
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof GetLessorUserMeResponseLessorUser
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof GetLessorUserMeResponseLessorUser
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof GetLessorUserMeResponseLessorUser
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GetLessorUserMeResponseLessorUser
     */
    imageUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetLessorUserMeResponseLessorUser
     */
    isApprovalActive: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetLessorUserMeResponseLessorUser
     */
    lessorBranchId?: number;
    /**
     * 
     * @type {number}
     * @memberof GetLessorUserMeResponseLessorUser
     */
    lessorId: number;
    /**
     * 
     * @type {string}
     * @memberof GetLessorUserMeResponseLessorUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetLessorUserMeResponseLessorUser
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof GetLessorUserMeResponseLessorUser
     */
    updatedAt: string;
}

export function GetLessorUserMeResponseLessorUserFromJSON(json: any): GetLessorUserMeResponseLessorUser {
    return GetLessorUserMeResponseLessorUserFromJSONTyped(json, false);
}

export function GetLessorUserMeResponseLessorUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLessorUserMeResponseLessorUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auth0Id': json['auth0Id'],
        'companyName': json['companyName'],
        'createdAt': json['createdAt'],
        'email': json['email'],
        'id': json['id'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'isApprovalActive': json['isApprovalActive'],
        'lessorBranchId': !exists(json, 'lessorBranchId') ? undefined : json['lessorBranchId'],
        'lessorId': json['lessorId'],
        'name': json['name'],
        'phoneNumber': json['phoneNumber'],
        'updatedAt': json['updatedAt'],
    };
}

export function GetLessorUserMeResponseLessorUserToJSON(value?: GetLessorUserMeResponseLessorUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auth0Id': value.auth0Id,
        'companyName': value.companyName,
        'createdAt': value.createdAt,
        'email': value.email,
        'id': value.id,
        'imageUrl': value.imageUrl,
        'isApprovalActive': value.isApprovalActive,
        'lessorBranchId': value.lessorBranchId,
        'lessorId': value.lessorId,
        'name': value.name,
        'phoneNumber': value.phoneNumber,
        'updatedAt': value.updatedAt,
    };
}

