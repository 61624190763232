import { css, keyframes } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import React, { HTMLAttributes } from "react";
import { Color } from "../../../../tokens";

type LoadingHandlerProps = HTMLAttributes<HTMLDivElement> & {
  isLoading?: boolean;
  children?: React.ReactNode;
};

const LoadingHandler: React.FC<LoadingHandlerProps> = ({
  isLoading,
  children,
  ...divProps
}) =>
  isLoading ? (
    <div css={LoadingHandlerStyle} {...divProps}>
      <div css={LoadingHandlerInnerStyle}>
        <div css={spinnerStyle} aria-label="読み込み中"></div>
      </div>
    </div>
  ) : (
    <>{children}</>
  );

const LoadingHandlerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const LoadingHandlerInnerStyle = css`
  flex-shrink: 1;
`;

const spinnerAnimationKeyframes = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -1.5em;
    height: 4.5em;
  }
`;

const spinnerStyle = css`
  &,
  &::before,
  &::after {
    background-color: ${Color.Neutral.Base.Primary};
    animation: ${spinnerAnimationKeyframes} 0.8s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }

  & {
    position: relative;
    font-size: ${text.size[10]};
    animation-delay: -0.16s;
  }

  &::before,
  &::after {
    position: absolute;
    top: 0;
    content: "";
  }

  &::before {
    left: -2em;
    animation-delay: -0.32s;
  }

  &::after {
    left: 2em;
  }
`;

export default LoadingHandler;
