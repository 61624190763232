/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetLessorUserMeResponseLessorUser,
    GetLessorUserMeResponseLessorUserFromJSON,
    GetLessorUserMeResponseLessorUserFromJSONTyped,
    GetLessorUserMeResponseLessorUserToJSON,
} from './GetLessorUserMeResponseLessorUser';

/**
 * 
 * @export
 * @interface GetLessorUserMeResponse
 */
export interface GetLessorUserMeResponse {
    /**
     * 
     * @type {GetLessorUserMeResponseLessorUser}
     * @memberof GetLessorUserMeResponse
     */
    lessorUser: GetLessorUserMeResponseLessorUser;
}

export function GetLessorUserMeResponseFromJSON(json: any): GetLessorUserMeResponse {
    return GetLessorUserMeResponseFromJSONTyped(json, false);
}

export function GetLessorUserMeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLessorUserMeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lessorUser': GetLessorUserMeResponseLessorUserFromJSON(json['lessorUser']),
    };
}

export function GetLessorUserMeResponseToJSON(value?: GetLessorUserMeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lessorUser': GetLessorUserMeResponseLessorUserToJSON(value.lessorUser),
    };
}

